<template>
  <div>
    <component v-for="(modal, index) in getModals" v-bind:key="index" :is="modal.id" v-bind="modal" @close="actionCloseModal" />
  </div>
</template>
<script>
import fp from "lodash/fp";
import { mapActions, mapGetters } from "vuex";
import * as components from "@/modals"

export default {
  computed: {
    ...mapGetters({
      "getModals": "app/getModals"
    })
  },
  methods: {
    ...mapActions({
      "actionCloseModal": "app/actionCloseModal"
    })
  },
  components
}

</script>
